<template>
  <div>
    <CRNavigation
      v-append-parameters="urlParams"
      :branding-global="jobShop?.branding"
      :navigation-global="jobShop?.navigation"
      :social-media-global="jobShop?.['social-media']"
      :page-locale="jobShop?.locale"
      :job-shop-url="jobShop?.jobShopUrl"
      @emitted-click="
        (e) => {
          trackNavigationClicked(e);
        }
      "
    />

    <slot />

    <CRFooter
      v-append-parameters="urlParams"
      :social-media-global="jobShop?.['social-media']"
      :navigation-global="jobShop?.navigation"
      :branding-global="jobShop?.branding"
      :footer-global="jobShop?.footer"
      :job-shop-url="jobShop?.jobShopUrl"
      @cookie-settings-click="$openCCMWidget()"
    />
  </div>
</template>

<script setup lang="ts">
import { useJobShopData } from "@/composables/useJobShopData"

const { app: { cdnURL } } = useRuntimeConfig()
const { $openCCMWidget } = useNuxtApp()
const urlParams = useQueryHeaders(useRequestHeaders())

// Fetch JobShop data
const { jobShop, jobShopPending, jobShopError } = await useJobShopData()

// Set fonts & font-weights
useFonts(jobShop?.value)

// Use JobShop data to set the favicon with useHeadSafe
const brandingConfig = jobShop.value?.branding.config.general

useHeadSafe({
  htmlAttrs: {
    lang: jobShop.value?.locale,
  },
  link: [
    {
      rel: "icon",
      href: brandingConfig?.favicon.media.url,
    },
  ],
})
</script>
